<template>
  <div class="stude" v-touch:swipe.left="gotoPage"
   v-touch:swipe.right="onSwipeRight">
    <div class="title3">{{user.name}}</div>
    <div class="title">
        <div class="label">{{user.level_name}}</div>
        Evaluation report <span>学情报告</span>
    </div>
    <div class="results">
        <div class="results-item">
            <div class="title4">Performance<span>演出情况</span></div>
            <div class="results-imgs">
                <img :src="item.value" alt="" v-for="item in play.property" :key="item.id">
            </div>
        </div>
        <div class="results-item results-item2">
            <div class="title4">Grade examination<span>考级情况</span></div>
            <div class="results-imgs">
                <div style="text-align:center;margin-right: 10px;"
                 v-for="item in exam.property" :key="item.id">
                    <img style="margin: auto;" :src="item.value" alt="">
                    <div style="font-size:10px;font-weight: bolder;">{{item.text}}</div>
                </div>
            </div>
        </div>
        <div class="results-item  results-item2">
            <div class="title4">Competition situation<span>比赛情况</span></div>
            <div class="results-imgs">
                <div style="text-align:center;margin-right: 10px;"
                 v-for="item in game.property" :key="item.id">
                    <img style="margin:auto;"  :src="item.value" alt="">
                    <div style="font-size:10px;font-weight: bolder;">{{item.text}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="results-data">
        <div class="results-data-item">
            <div class="title4">Interaction rate <span>出勤作业</span></div>
            <div class="results-data-chart results-data-chart2">
                <div class="results-data-chart-list results-data-chart-list2">
                    <div class="results-data-chart-list-item"
                     v-for="item in moving.property" :key="item.id">
                        {{item.name}}<div class="border"></div> <span>{{item.value}}</span>
                    </div>
                </div>
                <div class="results-data-chart-content">
                    <div class="lv">
                        <div class="num">{{moving.sum_percent}}</div>
                        <div class="numtitle">出勤率</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="results-data-item">
            <div class="title4">Enthusiasm<span>积极性</span></div>
            <div class="results-data-chart">
                <div class="results-data-chart-list">
                    <div class="results-data-chart-list-item"
                    v-for="item in active.property" :key="item.id">
                        {{item.name}} <div class="border"></div> <span>{{item.value}}</span>
                    </div>
                </div>
                <div class="results-data-chart-content">
                    <div class="lv">
                        <div class="num">{{active.sum_percent}}</div>
                        <div class="numtitle">积极性</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="results-data-item">
            <div class="title4">Attendance<span>出勤率</span></div>
            <div class="results-data-table">
                <div class="results-data-ratio">
                    <div class="num1">{{attendance}}<span>%</span></div>
                </div>
                <div class="results-data-table-list">
                    <div class="results-data-table-item"
                         :class="item.sign_status==0&&item.is_up==1
                         ?'results-data-table-color':item.is_up==0?'is_up':''"
                        v-for="(item, index) in attendanceArr" :key="index">
                        {{item.name}} <div v-if="item.sign_status==0&&item.is_up==1">未出勤</div>
                     </div>
                </div>
            </div>
        </div> -->
        <div class="results-data-item" style="display:none;">
            <div class="title4">Job submission rate <span>作业提交</span></div>
            <div class="results-data-table">
                <div class="results-data-ratio">
                    <div class="num1">{{task}}<span></span></div>
                    <!-- <div class="num2">环比0.29<span>↑</span></div> -->
                </div>
                <div class="results-data-table-list">
                    <div class="results-data-table-item"
                        :class="item.have_task=='yes'&&item.have_commit=='no'&&item.is_up==1?
                        'results-data-table-color':
                        (item.is_up==0||item.have_task=='no')?'is_up':''"
                        v-for="(item, index) in taskArr" :key="index">
                        {{item.name}}
                        <div v-if="(item.have_task=='yes'&&item.have_commit=='no'&&item.is_up==1)">
                            未提交
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    <div class="to" @click="gotoPage">
        <div class="to-left" @click="gotoPage">
            <div class="to-t1">Capability Analysis</div>
            <div class="to-t2">七大能力解析</div>
        </div>
        <img src="../assets/img/home/right.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      user: '',
      studentinfo: '',
      attendance: '', // c出勤率
      attendanceArr: [],
      task: '', // 作业提交情况
      taskArr: [],
      performance: '',
      play: '', // 演出情况
      exam: '', // 考级情况
      game: '', // 比赛情况
      moving: '',
      active: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
    this.getStudentinfo();
    this.getAttendance();
    this.getTask();
  },
  mounted() {

  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    gotoPage() {
      this.$router.push({
        name: 'CapabilityAnalysis',
      });
    },
    getTask() {
      const that = this;
      this.$server.fetch('/api/study/report/task', {
        student_id: this.user.student_id,
      }).then((res) => {
        if (res.code === 200) {
          that.task = res.data.level;
          that.taskArr = res.data.signInfo;
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    getAttendance() {
      const that = this;
      this.$server.fetch('/api/study/report/attendance', {
        student_id: this.user.student_id,
      }).then((res) => {
        if (res.code === 200) {
          that.attendance = res.data.attendance;
          that.attendanceArr = res.data.signInfo;
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    getStudentinfo() {
      const that = this;
      this.$server.fetch('/api/study/report/index', {
        student_id: this.user.student_id,
      }).then((res) => {
        if (res.code === 200) {
          that.studentinfo = res.data;
          that.game = res.data.game;
          that.play = res.data.play;
          that.exam = res.data.exam;
          that.moving = res.data.moving;
          that.active = res.data.active;
        } else {
        //   that.$toast(res.message);
        }
      });
    },
    handleChange() {

    },
  },
};
</script>
<style lang="less">
.stude{
    position: relative;
    padding: 0.2rem 0.31rem;
    width: 100vw;
    min-height: 100vh;
    background: #FFFDFA no-repeat;
    background-image: url('../assets/img/login/bg.png') ;
    background-size: 100% auto;
    .title3{
        font-size: 0.14rem;
        text-align: center;
        height: 0.33rem;
        line-height: 0.33rem;
        color: #000;
    }
    .title4{
        font-weight: bold;
        span{
        font-size: 0.12rem;
        color: #66635C;
        margin-left: 0.08rem;
        }
    }
    .title{
        display: flex;
        align-items: center;
        font-weight: bold;
        span{
            font-size: 0.12rem;
            color: #66635C;
            margin-left: 0.08rem;
        }
        .label{
            width: 0.41rem;
            height: 0.23rem;
            line-height: 0.23rem;
            font-size: 0.18rem;
            text-align: center;
            color: #fff;
            background: linear-gradient(137deg, #FCE6B4 0%, #EEBD6E 100%);
            border-radius: 0.03rem;
            margin-right: 0.06rem;
            margin-top: -0.04rem;
        }
    }
    .results{
        display: flex;
        padding: 0.17rem 0.16rem;
        background: #fff;
        border-radius: 0.1rem;
        border: 0.01rem solid #F0E9D8;
        margin: 0.19rem auto 0.23rem;
        .results-item{
            flex: 1;
            .results-imgs{
                height: 0.64rem;
                display: flex;
                align-items: center;
                margin-top: 0.21rem;
                img{
                    height: 0.4rem;
                    margin-right: 0.2rem;
                }
            }
        }
        .results-item2{
            flex: 1;
            .results-imgs{
                height: 0.64rem;
                display: flex;
                align-items: center;
                margin-top: 0.21rem;
                img{
                    height: 0.64rem;
                    margin-right: 0.2rem;
                }
            }
        }
    }
    .results-data{
        overflow: hidden;
        margin-bottom: 0.67rem;
        .results-data-item{
            width: 4.57rem;
            float: left;
            margin-right: 0.23rem;
            .title4{
                height: 0.38rem;
                line-height: 0.38rem;
                border-bottom: 0.01rem solid #F0E9D8;
            }
            .results-data-chart{
                display: flex;
                justify-content: space-between;
                padding: 0.2rem 0;
                .results-data-chart-content{
                    width: 1.37rem;
                    height: 1.37rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: url('../assets/img/student/huan.png');
                    background-size: 100% 100%;
                    margin-right: 0.5rem;
                    .num{
                        font-size: 0.35rem;
                        font-weight: bold;
                        color: #946A27;
                        line-height: 0.38rem;
                    }
                    .numtitle{
                        font-size: 0.12rem;
                        line-height: 0.12rem;
                        margin-top: 0.04rem;
                    }
                }
                .results-data-chart-list{
                    width: 2.4rem;
                    .results-data-chart-list-item{
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        font-size: 0.12rem;
                        line-height: 0.34rem;
                        // padding-left: 0.05rem;

                        .border{
                            width: 1.2rem;
                            height: 0.01rem;
                            background-image:
                            linear-gradient(to right, #EFF1F4 0%, #EFF1F4 50%, transparent 50%);
                            background-size: 0.1rem 0.01rem;
                            background-repeat: repeat-x;
                            margin: 0 0.04rem;
                        }
                        span{
                            font-size: 0.14rem;
                            color: #000;
                            font-weight: bold;
                        }
                    }
                    .results-data-chart-list-item::before{
                        content: '';
                        display: inline-block;
                        width: 0.08rem;
                        height: 0.08rem;
                        border-radius: 50%;
                        background: #FFD89A;
                        margin-right: 0.05rem;
                    }
                     .results-data-chart-list-item:nth-child(2)::before{
                        background: #F9C889;
                    }
                     .results-data-chart-list-item:nth-child(3)::before{
                        background: #DAC5A3;
                    }
                     .results-data-chart-list-item:nth-child(4)::before{
                        background: #EBE0CC;
                    }
                }
                .results-data-chart-list2{
                    height: 1.02rem;
                }
            }
            .results-data-chart{
                align-items: center;
            }
            .results-data-table{
                padding: 0.23rem 0.18rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .results-data-ratio{
                    width: 1.4rem;
                    // text-align: center;
                    // margin-right: 0.4rem;
                    .num1{
                        font-size: 0.26rem;
                        color: #946A27;
                        font-weight: bold;
                        height: 0.42rem;
                        span{
                            font-size: 0.14rem;
                            font-weight: 200;
                            margin-left: 0.01rem;
                        }
                    }
                    .num2{
                        height: 0.17rem;
                        line-height: 0.17rem;
                        color: #F9A430;
                        font-size: 0.12rem;
                        vertical-align: bottom;
                        span{
                            // font-size: 0.12rem;
                            margin-left: 0.01rem;
                        }
                    }
                }
                .results-data-table-list{
                    width: 2.8rem;
                    overflow: hidden;
                    .results-data-table-item{
                        float: left;
                        margin-right: 0.21rem;
                        width: 0.39rem;
                        height: 0.39rem;
                        line-height: 0.39rem;
                        text-align: center;
                        font-size: 0.16rem;
                        color: #000;
                        font-weight: bold;
                        margin-right: 0.21rem;
                        margin-bottom: 0.12rem;
                        position: relative;
                    }
                    .is_up{
                        color: #999;
                    }
                    .results-data-table-item:nth-child(5n){
                        margin-right: 0;
                    }
                    .results-data-table-color{
                        color: #FF5B00;
                        position: relative;
                        div{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 0.12rem;
                            font-size: 0.12rem;
                            font-weight: 300;
                            transform: scale(0.75);
                        }
                    }
                }
            }
        }
        .results-data-item:nth-child(2n){
            margin-right: 0;
        }
    }
    .to{
        position: absolute;
        display: flex;
        align-items: center;
        right: 0.32rem;
        bottom: 0.25rem;
        .to-left{
            font-size: 0.14rem;
            font-weight: 400;
            color: #333029;
            line-height: 0.17rem;
            .to-t2{
                font-size: 0.12rem;
                opacity: 0.8;
                transform: scale(0.75);
                transform-origin: 0 0;
            }
        }
        img{
            width: 1.04rem;
            height: 0.1rem;
            margin-left: 0.16rem;
        }
    }
}
</style>
